document.addEventListener('DOMContentLoaded', ()=> {

    const anim = document.querySelectorAll(".anim");

    const options = {
        root: null,
        threshold: 0
    }

    const observer = new IntersectionObserver((entries, observer)=> {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add("anim--visible");
                
                //if (!entry.target.classList.contains("anim--infinite")) {
                   // observer.unobserve(entry.target);
                //}
                
                
            } else {
                //entry.target.classList.remove("anim--visible");
                return;
            }
        });
    }, options);
    
    window.addEventListener('load', ()=> {
        anim.forEach(card => observer.observe(card));        
    })

}, false);
