document.addEventListener('DOMContentLoaded', () => {

    
    const init = () => {
        document.documentElement.classList.add('is-loaded');
        
        setTimeout(() => {
        }, 250);  
    };
    
    window.addEventListener('load', init);

}, false);